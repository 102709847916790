import type { ComponentInternalInstance } from 'vue'
import type {
  UseSubmitHandlerContext,
  UseSubmitHandlerOptions,
  UseSubmitHandlerRequest,
} from '@shared/composables/useSubmitHandler'
import type { UseConfirmOptions } from '@shared/composables/useConfirm'
import type { ToastOptions } from 'vue3-toastify'

export type UseConfirmHandlerRequest<
  D = unknown,
  R = unknown,
> = UseSubmitHandlerRequest<D, R>

export interface UseConfirmHandlerContext<D = unknown> {
  instance: ComponentInternalInstance
  data: D
}

export type UseConfirmHandlerSubmitOptions<D = unknown, R = unknown> = Omit<
  UseSubmitHandlerOptions<D, R>,
  | 'successMessage'
  | 'errorMessage'
  | 'loadingMessage'
  | 'toastOptions'
  | 'emitEvent'
  | 'shouldEmit'
  | 'shouldThrow'
>

export type UseConfirmHandlerModalOptions = Omit<
  UseConfirmOptions,
  'bypass' | 'confirm' | 'close' | 'loading'
>

export interface UseConfirmHandlerOptions<D = unknown, R = unknown> {
  successMessage?: UseSubmitHandlerOptions<D, R>['successMessage']
  errorMessage?: UseSubmitHandlerOptions<D, R>['errorMessage']
  loadingMessage?: UseSubmitHandlerOptions<D, R>['loadingMessage']
  toastOptions?: ToastOptions
  modal?: UseConfirmHandlerModalOptions
  beforeOpen?: (
    context: UseConfirmHandlerContext<D>,
  ) => Promise<false | void> | false | void
  onConfirm?: (
    response: R | void,
    context: UseConfirmHandlerContext<D>,
  ) => Promise<void> | void
  onClose?: () => Promise<void> | void
  bypass?: (context: UseConfirmHandlerContext<D>) => boolean
  submitHandlerOptions?: UseConfirmHandlerSubmitOptions<D, R>
  emitEvent?: string
  shouldEmit?: boolean
  shouldThrow?: boolean
}

export type UseConfirmHandlerReturn<D = unknown, R = unknown> = (
  data?: D,
) => void

export const useConfirmHandler = <D = unknown, R = unknown>(
  request: UseConfirmHandlerRequest<D, R>,
  options: UseConfirmHandlerOptions<D, R> = {},
): UseConfirmHandlerReturn<D, R> => {
  // COMPONENT INSTANCE
  const instance = getCurrentInstance()

  if (!instance) {
    throw new Error('This must be called within a setup function.')
  }

  // GLOBAL
  const confirm = useConfirm()

  const {
    successMessage,
    errorMessage,
    loadingMessage,
    toastOptions,
    modal = {},
    beforeOpen = () => {},
    onConfirm = () => {},
    onClose = () => {},
    bypass = () => false,
    submitHandlerOptions = {},
    emitEvent,
    shouldEmit = false,
    shouldThrow = true,
  } = options

  const submitHandler = useSubmitHandler<D, R>(request, {
    ...submitHandlerOptions,
    successMessage,
    errorMessage,
    loadingMessage,
    toastOptions,
    emitEvent,
    shouldEmit,
    shouldThrow,
  })

  return async (data?: D) => {
    const context: UseConfirmHandlerContext<D> = {
      instance,
      data: data as D,
    }

    const _beforeOpen = await beforeOpen(context)

    if (_beforeOpen === false) {
      return
    }

    const loading = ref(false)

    confirm.require({
      ...modal,
      // @ts-expect-error - Reactivity
      loading,
      confirm: async () => {
        try {
          loading.value = true
          const response = await submitHandler(data)
          loading.value = false
          onConfirm(response, context)
        } catch (error) {
          loading.value = false
          if (shouldThrow) throw error
        }
      },
      close: () => {
        onClose()
      },
      bypass: () => bypass(context),
    })
  }
}
